//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import "./semaphore.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png"; 
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { delete_semaphore, get_semaphore } from "../../services/reports/reports";
import TableContainer from "../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import OPERATION_SEMAPHORE from "./tools/op_semaphore";

const TableSemaphore= () => {
  
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;

  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);


useEffect(()=>{
  handleSubmit();
},[]);

function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n===true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}
//proceso de consultar todos los semaforos
const handleSubmit = async () => {
    console.log("entra a consultard data de semaforos");
    if(entrada===0){
      entrada=entrada+1;
      setmenssage("Consultando Semaforo...");
      setmodalGeneral(true);
      setmodalT(true);
    }else{
      setmodalT(true);
    }
    setopen(true);
    setData([]);
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await get_semaphore();
            setData(d===null?[] : d.data.semaphores);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }catch(err){
            setData([]);
            setopen(false)
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err); 
    }
};

const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            id="crearRol"
            src={CREATE}
            onClick={() => {
              CustomDialog(               
             <div>
                {/* <CreateSemaphore handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></CreateSemaphore> */}
                <OPERATION_SEMAPHORE operation={"CREATE"} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}/>
              
             </div>,
           {
             className: "modalTItle",
             title:"Crear Nuevo Semáforo",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Crear Semáforo"
            className="cancel"
            alt=""
          ></img>
        </div>
      ),
      
      id: "Acciones",
      Cell: ({ row }) => {
      const DeleteSector = async () =>{  
      
      setmodalT(true);
      setData([]);
      setopen(true);
        try {
          var rf_token = await refresh_token();
          if(rf_token===true){
            setTimeout(async() => {
              try{
                const dt = await delete_semaphore(row.original.id);
                console.log(dt);
                alertas("Semáforo Eliminado", true);
                handleSubmit();
              }catch(err){
                console.log("error", err);
                setopen(false);
                alertas("Error, no se pudo eliminar el semáforo", false);
                setmodalT(false);
                handleSubmit();
              }
            }, 1000);
          }else{} 
        } catch (err) {
            console.log(err);
        }   
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar el semáforo?','Eliminar Semáforo','Si','No');
                  if(result){
                    DeleteSector()
                    }
                  }            
                }
              className="centerText, cancel"
              title="Eliminar Semáforo"
              alt=""
            ></img>
              &nbsp;
            <img
              src={Edit}
              onClick={() => {
                   CustomDialog(               
                 <div>
                   {/* <EditSemaphore row={row} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></EditSemaphore> */}
                   <OPERATION_SEMAPHORE operation={"EDIT"} row={row} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}/>
               
                 </div>,
               {
                 className: "modalTItle",
                 title:"Editar Semáforo",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              className="centerText, cancel"
              title="Editar Semáforo"
              alt=""
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Color",
      accessor:(d)=>(
        <div className="semaforo-celda" style={{"backgroundColor": d.color_hex}}></div>
      )
    },
    {
      Header: "Cantidad Mínima",
      accessor: (d) => {
        return d.minimum
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.minimum)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.minimum);
      },
    },
    {
      Header: "Cantidad Máxima",
      accessor: (d) => {
        return d.max
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.max)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.max);
      },
    }
    ],
    []
);
  return (
    <>
    <BREADCRUMBS niveles={
        [
          {label: "CONSULTAR SEMAFORO", path: null},
        ]
        }
    ></BREADCRUMBS>

<div className="TableSemaphore">
  <div className="">         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
          <>
         
          <StickyTable>
            <TableContainer
              paginado={"Semáforo"}
              consultas={"Semáforos Consultados"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
          </>   
          </div>
        </div>
    </>
  );
}

export default TableSemaphore;
