//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, useDialog, Confirm, Alert } from "react-st-modal";
import NotificationSystem from "react-notification-system";
//COMPONENTES
import { SelectColumnFilter } from "../../../components_cfdi/filters";
import TableContainer from "../../../main_components/Table/TableContainer";
import SubTable from "../tools/subTable";
import CREATE_UPDATE from "../tools/set_bussines_p";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import AlertResponse from "../../../main_components/alerts/alertResponse";
import { get_supplier,delete_business_partner } from "../../../services/businesspartners/businesspartners";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
//ESTILOS
// import "../componentsCFDI/estilo.css";
// import "./tableclientes.css";
// import "../componentsCFDI/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../../imgComponents/save.png";
import DELETE from "../../../imgComponents/borrar.png";
import CREATE from "../../../imgComponents/create.png";
const SUPPLIER = () => {
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(1);
  const [isLoding, setIsLoding] = useState(false);
  const [menssage, setmenssage] = useState("Consultando proveedoes...");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [modalT, setmodalT] = useState(true);
  const notificationSystemRef = useRef();

 

  const handle_supplier = async (msjAler) => {
    try {
      var dt=await get_supplier();
      setmodalT(false);
      setData(dt?.data?.business_partner);
      setIsLoding(false);
      setmodalT(false);

      if (msjAler?.length > 1) {
        notificationSystemRef.current.addNotification({
          message: (
            <AlertResponse msj={msjAler} view={true}></AlertResponse>
          ),
          level: "success",
          position: "br",
          autoDismiss: 10,
        });
      }
    } catch (err) {
      setData([]);
      notificationSystemRef.current.addNotification({
        message: (
          <AlertResponse
            msj={JSON.stringify(err?.response?.data)}
            view={false}
          ></AlertResponse>
        ),
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
      setIsLoding(false);
      
      setmodalT(false);
      
    }
  }
  const handle_delete = async (id) => {
      setmodalT(true);
    try {
      await delete_business_partner(id);
      setmodalT(false);
      handleReconsulta(true,"Proveedor eliminado correctamente");
    } catch (err) {
      notificationSystemRef.current.addNotification({
        message: (
          <AlertResponse
            msj={JSON.stringify(err?.response?.data)} 
            view={false}
          ></AlertResponse>
        ),
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
      setmodalT(false);
    }
  };
 
  useEffect(() => {
    handle_supplier();
  }, []);

  const handleReconsulta = (reconsultar, msjAler) => {
    if (reconsultar === true) {
      handle_supplier(msjAler);
    } else {
      console.log("no se reconsulto");
    }
  };
  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <>
              <img
                alt=""
                title="Crear Proveedor "
                src={CREATE}
                className="cursorPointer"
                onClick={async () => {
                  const result = await CustomDialog(
                    <CREATE_UPDATE 
                    actualiza={false} 
                    method={"CREATE"}
                    supplierdata={true}
                    code={""}
                    business_partner={""}
                    tax_regime={""}
                    name={""}
                    first_last_name={""}
                    second_last_name={""}
                    balance={0}
                    credit_days={0}
                    business_partner_type={"PROVEEDOR"}
                    capital_regime={""}
                    partner_type={""}
                    person_type={""}
                    cfdi_use={""}
                    phone={""}
                    metodo_pago={""}
                    paymethod={""}
                    allow_invoicing={""}
                    street={""}
                    outdoor_number={""}
                    inside_number={""}
                    neighborhood={""}
                    zip_code={""}
                    city={""}
                    state={""}
                    email={""}
                    country={""}
                    reference={""}
                    address_type={""}
                    subsidiary={""}
                    tax_residence={""}
                    rfc={""}
                    num_reg_id_trib={""}
                    town={""}
                    partner_type_data={"PROPIO"}
                    uso_cfdi_data={"G01"}
                    state_data={"VERACRUZ DE IGNACIO DE LA LLAVE"}
                    address_type_data={"FISCAL"}
                    tax_regime_data={"601"}
                    subsidiary_data={"MATRIZ"}
                    metodo_pago_data={"PUE"}
                    account_data={""}
                    id_accounting_account_fk={null}

                    ></CREATE_UPDATE>,
                    {
                      className: "modalSetBussnes",
                      title: "Crear Proveedor",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handleReconsulta(
                      result,
                      "Proveedor creado correctamente"
                    );
                  }
                }}
              ></img>
            </>
          );
        },
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("socioHabilitado", row.original.id);
          }

          return (
            <a {...row.getToggleRowExpandedProps()}>
              {row?.isExpanded ? (
                <span>🔼</span>
              ) : (
                <span onClick={guardardato}>🔽</span>
              )}
            </a>
          );
        },
      },
      {
        Header: () => {
          return (
            <>
              <p style={{ color: "white" }}>Acciones</p>
             
            </>
          );
        },
        id: "Acciones",

        Cell: ({ valor, row }) => {
         
          return (
            <div>
              <img
                title="Actualizar Proveedor"
                src={SAVE}
                alt=""
                className="cursorPointer"
                onClick={async () => {
                  const result = await CustomDialog(
                    <CREATE_UPDATE 
                    method={"UPDATE"}
                    modul={"PROVEEDOR"}
                    actualiza={false} 
                    supplierdata={true}
                    code={row.original.code}
                    business_partner={row.original.business_partner}
                    tax_regime={row.original.tax_regime}
                    name={row.original.name}
                    rfc={row.original.rfc}
                    first_last_name={row.original.first_last_name}
                    second_last_name={row.original.second_last_name}
                    balance={row.original.balance}
                    credit_days={row.original.credit_days}
                    business_partner_type={row.original.business_partner_type}
                    capital_regime={row.original.capital_regime}
                    partner_type={row.original.partner_type}
                    person_type={row.original.person_type}
                    cfdi_use={row.original.cfdi_use}
                    phone={row.original.phone}
                    email={row.original.email}
                    metodo_pago={row.original.metodo_pago}
                    paymethod={row.original.paymethod}
                    allow_invoicing={row.original.allow_invoicing}
                    street={row.original.street}
                    outdoor_number={row.original.outdoor_number}
                    inside_number={row.original.neighborhood}
                    neighborhood={row.original.neighborhood}
                    zip_code={row.original.zip_code}
                    city={row.original.city}
                    state={row.original.state}
                    country={row.original.country}
                    reference={row.original.reference}
                    address_type={row.original.address_type}
                    subsidiary={row.original.subsidiary}
                    tax_residence={row.original.tax_residence===null?"":row.original.tax_residence}
                    num_reg_id_trib={row.original.num_reg_id_trib}
                    town={row.original.town}
                    partner_type_data={row.original.partner_type}
                    uso_cfdi_data={row.original.uso_cfdi}
                    state_data={row.original.state}
                    address_type_data={row.original.address_type}
                    tax_regime_data={row.original.tax_regime}
                    subsidiary_data={row.original.subsidiary}
                    metodo_pago_data={row.original.metodo_pago}
                    account_number={row.original.account_number}
                    account_data={row.original.name_accounting_account+"-"+row.original.code_accounting_account}
                    id_accounting_account_fk={row.original.id_accounting_account_fk}
                    id={row.original.id}
                    ></CREATE_UPDATE>,
                    {
                      className: "modalSetBussnes",
                      title: "Actualizar Proveedor",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handleReconsulta(
                      result,
                      "Proveedor actualizado correctamente"
                    );
                  }
                }}
              ></img>

              <img
                src={DELETE}
                alt=""
                className="centerText, cancel"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este Proveedor?",
                    "Eliminar Proveedor",
                    "Si",
                    "No"
                  );

                  if (result) {
                    handle_delete(row.original.id);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Razón Social",
        accessor: "business_partner",
      },
      {
        Header: "RFC",
        accessor: "rfc",
      },
      {
        Header: "Tipo de Socio",
        accessor: "business_partner_type",
        Filter: SelectColumnFilter,

      },
      {
        Header: "E-MAIL",
        Filter: SelectColumnFilter,
        accessor: (d) => {
          return d.email?.length > 1 ? "SI" : "NO";
        },
      },
      {
        Header: "Días de Crédito",
        accessor: "credit_days",
      },
      {
        Header: "Código Externo",
        accessor: "outer_code",
      },
      {
        Header: "Cuenta",
        accessor: "account_number",
      },
      {
        Header: "Código de la cuenta contable",
        accessor: "code_accounting_account",
      },
    ],
    []
  );

  return (
    <div>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <BREADCRUMBS
        niveles={[{ label: "CONSULTAR PROVEEDORES", path: null }]}
      ></BREADCRUMBS>

      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <div className="">
        <div className="">
          <div className="containerBussnesP">
            <div className="expen-1 color-1" style={{ paddingTop: "10px" }}>
              <select
                id="canceladas-si-no"
                onChange={(event) => setCancel(event.target.value)}
              >
                <option>Habilitados/Deshabilitados</option>
                <option value={1}>Habilitados</option>
                <option value={0}>Deshabilitados</option>
              </select>
            </div>

            <div className="expen-2 color-1">
              <button className="button" onClick={handle_supplier} type="button">
                Buscar
              </button>
            </div>
          </div> 

          <div className="TableCOnsultaClientes">
            <TableContainer
              exportEx={true}
              columns={columns}
              data={data}
              exportar={true}
              Gmodal={modalT}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <SubTable />
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SUPPLIER;