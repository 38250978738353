//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { CustomDialog} from "react-st-modal";
import LoadingScreen from "react-loading-screen";
import { StickyTable } from "react-sticky-table";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

//COMPONENTES
import TableContainer from "../../../main_components/Table/TableContainer";
import { SelectColumnFilter, MultiSelectColumnFilter} from "../../filters";
import ReenvioCorreo from "./report_email/send_invoice";
import SubTable from "./detail/detail_invoce";
import CANCEL_INVOICE from "../cancel_invoice/cancel_invoice";
import { base64toBlob,base64toBlobxml } from "../../../main_components/donwloadfuncion/download";
import { get_invoices } from "../../../services/invoices/invoice";
import { download_invoice } from "../../../services/invoices/invoice";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { get_status } from "../../../services/invoices/invoice";
import Checkbox from "@mui/material/Checkbox";

import FormControlLabel from "@mui/material/FormControlLabel";
//ESTILOS
import "../../estilo.css";
import "../../modal.css";
import "../../table.css";

//IMAGENES
import Spinner from "../../../imgComponents/S.gif";
import tache from "../../../imgComponents/tache.png";
import tacheCANCEL from "../../../imgComponents/cancelado.png";
import PDF from "../../../imgComponents/pdf.png";
import PDFCANCEL from "../../../imgComponents/pdfcancel.png";
import XML from "../../../imgComponents/xml.png";
import XMLCANCEL from "../../../imgComponents/xmlcancel.png";
import EMAIL from "../../../imgComponents/correoElectronico.png";
import REPORT from "../../../imgComponents/invoice_cp.png";
import {styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { COLUMS_SHOW } from "../../../main_components/Table/ColumsShow";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import { MaterialUISwitch } from "../../../main_components/material_switch/switch";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Consult_invoice = () => {



  const [data, setData] = useState([]);
  const [ff, setff] = useState("");
  const [fi, setfi] = useState("");
  const [rfc, setRFC] = useState("");
  const [sdn, setSDN] = useState("");
  const [cancel, setCancel] = useState(null);
  const [tipodoc, setTipoDoc] = useState("");
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [isLoding, setIsLoding] = useState(false);
  const [open, setOpen] = useState(false);
  const [reconsult, setreconsult] = useState(false);
  const [filtersColumsView, setfiltersColumsView] = useState(true);


  //variables para visualizar columnas
  console.log( localStorage.getItem("facturas_viewfecha"));
  const [viewfecha, setviewfecha]=useState(
    localStorage.getItem("facturas_viewfecha") === null
    ? true
    : localStorage.getItem("facturas_viewfecha") === "true"
    ? true
    :false
  );
  const [viewticket, setviewticket]=useState(
    localStorage.getItem("facturas_viewticket") === null
    ? false
    : localStorage.getItem("facturas_viewticket") === "true"
    ? true
    :false
  );
  const [viewpos, setviewpos]=useState(
    localStorage.getItem("facturas_viewpos") === null
    ? false
    : localStorage.getItem("facturas_viewpos") === "true"
    ? true
    :false
  );
  const [viewseriefolio, setviewseriefolio]=useState(
    localStorage.getItem("facturas_viewseriefolio") === null
    ? false
    : localStorage.getItem("facturas_viewseriefolio") === "true"
    ? true
    :false
  );
  const [viewserie, setviewserie]=useState(
    localStorage.getItem("facturas_viewserie") === null
    ? true
    : localStorage.getItem("facturas_viewserie") === "true"
    ? true
    :false
  );
  const [viewfolio, setviewfolio]=useState(
    localStorage.getItem("facturas_viewfolio") === null
    ? true
    : localStorage.getItem("facturas_viewfolio") === "true"
    ? true
    :false
  );
  const [viewcliente, setviewcliente]=useState(
    localStorage.getItem("facturas_viewcliente") === null
    ? true
    : localStorage.getItem("facturas_viewcliente") === "true"
    ? true
    :false
  );
  const [viewrfc, setviewrfc]=useState(localStorage.getItem("facturas_viewrfc") === null? true: localStorage.getItem("facturas_viewrfc") === "true"? true:false);
  const [viewsubtotal, setviewsubtotal]=useState(
    localStorage.getItem("facturas_viewsubtotal") === null
    ? true
    : localStorage.getItem("facturas_viewsubtotal") === "true"
    ? true
    :false
  );
  const [viewdescuento, setviewdescuento]=useState(
    localStorage.getItem("facturas_viewdescuento") === null
    ? true
    : localStorage.getItem("facturas_viewdescuento") === "true"
    ? true
    :false
  );
  
  const [viewimpuesto, setviewimpuesto]=useState(
    localStorage.getItem("facturas_viewimpuesto") === null
    ? true
    : localStorage.getItem("facturas_viewimpuesto") === "true"
    ? true
    :false
  );
  const [viewtotal, setviewtotal]=useState(
    localStorage.getItem("facturas_viewtotal") === null
    ? true
    : localStorage.getItem("facturas_viewtotal") === "true"
    ? true
    :false
  );
  const [viewmetodopago, setviewmetodopago]=useState(
    localStorage.getItem("facturas_viewmetodopago") === null
    ? true
    : localStorage.getItem("facturas_viewmetodopago") === "true"
    ? true
    :false
  );
  const [viewmeforma_pago, setviewmeforma_pago]=useState(
    localStorage.getItem("facturas_viewmeforma_pago") === null
    ? true
    : localStorage.getItem("facturas_viewmeforma_pago") === "true"
    ? true
    :false
  );
  const [viewcomprobante, setviewcomprobante]=useState(
    localStorage.getItem("facturas_viewcomprobante") === null
    ? true
    : localStorage.getItem("facturas_viewcomprobante") === "true"
    ? true
    :false
  );
  const [viewestado, setviewestado]=useState(
    localStorage.getItem("facturas_viewestado") === null
    ? true
    : localStorage.getItem("facturas_viewestado") === "true"
    ? true
    :false
  );
  const [viewusuario, setviewusuario]=useState(
    localStorage.getItem("facturas_viewusuario") === null
    ? true
    : localStorage.getItem("facturas_viewusuario") === "true"
    ? true
    :false
  );
  
  useEffect(()=>{
    localStorage.setItem("facturas_viewfecha", viewfecha);
    localStorage.setItem("facturas_viewticket", viewticket);
    localStorage.setItem("facturas_viewpos", viewpos);
    localStorage.setItem("facturas_viewseriefolio", viewseriefolio);
    localStorage.setItem("facturas_viewserie", viewserie);
    localStorage.setItem("facturas_viewfolio", viewfolio);
    localStorage.setItem("facturas_viewcliente",viewcliente);
    localStorage.setItem("facturas_viewrfc", viewrfc);
    localStorage.setItem("facturas_viewsubtotal", viewsubtotal);
    localStorage.setItem("facturas_viewdescuento", viewdescuento);
    localStorage.setItem("facturas_viewimpuesto", viewimpuesto);
    localStorage.setItem("facturas_viewtotal", viewtotal);
    localStorage.setItem("facturas_viewmetodopago", viewmetodopago);
    localStorage.setItem("facturas_viewcomprobante", viewcomprobante);
    localStorage.setItem("facturas_viewestado", viewestado);
    localStorage.setItem("facturas_viewusuario", viewusuario); 
    localStorage.setItem("facturas_viewmeforma_pago",viewmeforma_pago);
  },[
    viewfecha,
    viewticket,
    viewpos,
    viewseriefolio,
    viewserie,
    viewfolio,
    viewcliente,
    viewrfc,
    viewsubtotal,
    viewdescuento,
    viewimpuesto,
    viewtotal,
    viewmetodopago,
    viewcomprobante,
    viewestado,
    viewusuario,
    viewmeforma_pago
  ])


  const handleSubmit = async (event) => {
    event?.preventDefault();
    setIsLoding(true);
    setData([]);
    try{
      var rf_token=await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            var dt = await get_invoices(fi, ff, rfc, sdn, tipodoc, cancel);
            setData(dt.data.slice().sort((a, b) => a.invoice_date < b.invoice_date));
            setIsLoding(false); 
          } catch (err) {
            setData([]);
            setIsLoding(false);
          }   
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  useEffect(() => { 
    handleSubmit(); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handle_status =async(id,uuid)=>{
    setmensajeDescarga("Verificando estado de la factura");
    setOpen(true);
    setreconsult(true)
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            const dt= await get_status(id,uuid);
            sessionStorage.setItem( "faCancel",dt?.data?.cancellation_request_id);
            setOpen(false);
            setmensajeDescarga("");
            setreconsult(false)
          } catch (err) {
            alert(JSON.stringify(err.response.data));
            setreconsult(false)
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  }
  const handlechecked = (event) => {
    setfiltersColumsView(event.target.checked);
  };


  const download = async (typedocumentexmlorpdf,id_invoce,fileName_document) => {
    setmensajeDescarga("Descargando");
    setOpen(true);
    try {
      const data = await download_invoice(
        typedocumentexmlorpdf,
        fileName_document,
        id_invoce
      );
      var dt = data.data.file;
      var blob;
      if(typedocumentexmlorpdf==="xml"){
        blob = base64toBlobxml(dt);
      }else{
        blob = base64toBlob(dt);
      }
      const blobUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      let arr = fileName_document.split("/");
      console.log(typedocumentexmlorpdf);
      downloadLink.download = arr[4] + `.${typedocumentexmlorpdf}`;
      downloadLink.click();
      setmensajeDescarga("");
      setOpen(false);
    } catch (err) {
      setOpen(false);
    }
  }



  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: "",
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("dataSuntablefiltro", row.original.uuid_sat);
            sessionStorage.setItem("dataSuntablefiltroID", row.original.id);
            console.log(row.original.folio);
          }

          return (
            <a {...row.getToggleRowExpandedProps()}>
              {row?.isExpanded ? (
                <span>🔼</span>
              ) : (
                <span onClick={guardardato}>🔽</span>
              )}
            </a>
          );
        },
      },
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          
          

          return (
            <div>
              <div className="contanerActionImg">
                <div className="fat-1">
                  <img
                  alt=""
                    style={{display: row.original.cancel === false ? "none" : ""}}
                    src={tacheCANCEL}
                    title="REVERIFICAR ESTADO DE LA FACTURA"
                    onClick={() => handle_status(row.original.id,row.original.uuid_sat)}
                  ></img>

                  <img
                  alt=""
                    src={tache}
                    style={{display: row.original.cancel === false ? "" : "none"}}
                    className="cancelIMG"
                    title="Cancelar Factura"
                    onClick={async () => {
                      if (
                        sessionStorage.getItem("PERMISOCANCEL") ===
                        "ROLE_CANCEL_INVOICE"
                      ) {
                        sessionStorage.setItem("rfcBusqueda",row.original.rfc_business_partner);
                        sessionStorage.setItem("rowUuid",row.original.uuid_sat);
                        sessionStorage.setItem("id_cancelF", row.original.id);
                        handle_status(row.original.id,row.original.uuid_sat);
                        
                        await CustomDialog(
                          <CANCEL_INVOICE
                            idCancel={row.original.id}
                          ></CANCEL_INVOICE>,
                          {
                            className: "modalCancelR",
                            title:"Seleccione el motivo por el cual desea cancelar la factura",
                            showCloseIcon: true,
                          }
                        );
                      } else {
                        alert("No tienes permisos para cancelar");
                      }
                    }}
                  ></img>
                </div>
                <div className="fat-2">
                  <img
                  alt=""
                    src={XML}
                    title="Descargar XML"
                    className="cancelIMG2"
                    onClick={() =>
                      download("xml", row.original.id, row.original.filename)
                    }
                  ></img>
                </div>

                <div className="fat-3">
                  <img
                  alt=""
                    src={PDF}
                    title="Descargar PDF"
                    className="cancelIMG2"
                    onClick={() =>
                      download("pdf", row.original.id, row.original.filename)
                    }
                  ></img>
                </div>

                <div className="fat-5">
                  <img
                    alt=""
                    src={XMLCANCEL}
                    style={{
                      display: row.original.cancel === false ? "none" : "",
                    }}
                    onClick={() =>
                      download(
                        "xml",
                        row.original.id,
                        row.original.filename_cancel
                      )
                    }
                    title="Descargar XML Cancel"
                  ></img>
                </div>

                <div className="fat-6">
                  <img
                    alt=""
                    src={PDFCANCEL}
                    style={{
                      display: row.original.cancel === false ? "none" : "",
                    }}
                    onClick={() =>
                      download(
                        "pdf",
                        row.original.id,
                        row.original.filename_cancel
                      )
                    }
                    title="Descargar PDF Cancel"
                  ></img>
                </div>
                <div className="fat-4">
                  <img
                    alt=""
                    src={EMAIL}
                    className="cancelIMG2"
                    title="ENVIAR CORREO"
                    onClick={async () => {
                      sessionStorage.setItem(
                        "FACTURAMANDAR",
                        row.original.uuid_sat
                      );

                       await CustomDialog(
                        <ReenvioCorreo
                          report={false}
                          id={row.original.id}
                          uuid={row.original.uuid_sat}
                        ></ReenvioCorreo>,
                        {
                          className: "modalTItle",
                          title:
                            'Nota: SEPARA LOS CORREOS ELECTRÓNICOS A TRAVÉS DE UNA "," SI DESEAS ENVIAR MÁS DE UN CORREO ELECTRÓNICO',
                          showCloseIcon: true,
                        }
                      );
                    }}
                  ></img>
                </div>
              </div>
            </div>
          );
        },
      },

      {
        Header: "Fecha",
        accessor: "invoice_date",
        show: viewfecha,
        id: "invoice_date"
      },
 
      {
        Header: "Serie",
        accessor: "serie",
        Filter: MultiSelectColumnFilter,
        filter: "includes",
        id: "serie",
        show: viewserie
      },
      {
        id: "cancel",
        Header: "Folio",
        accessor: "folio",
        show: viewfolio
      },
      {
        Header:"Serie/Folio",
        accessor:(d)=>{
          return d.serie+d.folio
        },
        show: viewseriefolio,
        id: "seriefolio"
        
      },
      {
        Header: "Cliente",
        accessor: "name_business_partner",
        show: viewcliente,
        id: "name_business_partner"
      },
      {
        Header: "RFC",
        accessor: "rfc_business_partner",
        show: viewrfc,
        id: "rfc_business_partner"
      },
      {
        Header: "Sub Total",
        accessor: (d) => {
          return d.subtotal
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.subtotal)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.subtotal);
        },
        show: viewsubtotal,
        id: "subtotal"
      },
      {
        Header: "Descuento",
        accessor: (d) => {
          return d.discount
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.discount)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.discount);
        },
        show: viewdescuento,
        id: "discount"
      },
      {
        Header: "Impuesto",
        accessor: (d) => {
          return d.tax
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.tax)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.tax);
        },
        show: viewimpuesto,
        id: "tax"
      },
      {
        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
        show: viewtotal,
        id: "total"
      },

      {
        Header: "Método de pago",
        accessor: "payment_method",
        id: "payment",
        Filter: SelectColumnFilter,
        filter: "equals",
        show: viewmetodopago,
      },

      {
        Header: "Forma de Pago",
        accessor: "forma_pago",
        id: "forma_pago",
        Filter: SelectColumnFilter,
        filter: "equals",
        show: viewmeforma_pago,
      },
      {
        Header: "Tipo de comprobante",
        accessor: "tipodecomprobante",
        Filter: MultiSelectColumnFilter,
        filter: "includes",
        show: viewcomprobante,
        id:"tipodecomprobante"
      },

      {
        Filter: SelectColumnFilter,
        filter: "equals",
        Header: "Estado",
        accessor: (d) => {
          return d.cancel === true
            ? d.status === "Vigente"
              ? "VIGENTE"
              : "CANCELADO"
            : "VIGENTE";
        },
        show: viewestado,
        id: "status"
      },

      {
        Header: "Usuario",
        Filter: SelectColumnFilter,
        filter: "equals",
        accessor: "username",
        show: viewusuario,
        id: "username"
      },
    ],
    [
      viewfecha,
      viewticket,
      viewpos,
      viewseriefolio,
      viewserie,
      viewfolio,
      viewcliente,
      viewrfc,
      viewsubtotal,
      viewdescuento,
      viewimpuesto,
      viewtotal,
      viewmetodopago,
      viewcomprobante,
      viewestado,
      viewusuario,
      viewmeforma_pago,
    ]
  );


  const datacolumns = [
    {
      view: viewfecha,
      setview: setviewfecha,
      label: "Fecha"
    },

    {
      view: viewserie,
      setview: setviewserie,
      label: "Serie"
    },
    {
      view: viewfolio,
      setview: setviewfolio,
      label: "Folio"
    },
    {
      view: viewseriefolio,
      setview: setviewseriefolio,
      label: "Serie/Folio"
    },
    {
      view: viewcliente,
      setview: setviewcliente,
      label: "Cliente"
    },
    {
      view: viewrfc,
      setview: setviewrfc,
      label:"RFC"
    },
    {
      view: viewsubtotal,
      setview: setviewsubtotal,
      label:"Subtotal"
    },
    {
      view: viewdescuento,
      setview: setviewdescuento,
      label:"Descuento"
    },
    {
      view: viewimpuesto,
      setview: setviewimpuesto,
      label:"Impuesto"
    },
    {
      view: viewtotal,
      setview: setviewtotal,
      label:"Total"
    },
    {
      view: viewmetodopago,
      setview: setviewmetodopago,
      label:"Metodo Pago"
    },

    {
      view: viewmeforma_pago,
      setview: setviewmeforma_pago,
      label:"Forma de Pago"
    },
    {
      view: viewestado,
      setview: setviewestado,
      label:"Estado"
    },
    {
      view: viewusuario,
      setview: setviewusuario,
      label:"Usuario"
    }
  ]


  return (
    <>
      <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR FACTURAS", path: null},
                ]
              }
      ></BREADCRUMBS>
    
    <div className="TableConsultarTodo">
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              >
                <img alt="" src={Spinner}></img>
              </Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              >
                {mensajeDescarga}
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>

      <div className="">
        {isLoding ? (
          <LoadingScreen
            className="LoadinScreen"
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc={Spinner}
            text={mensajeDescarga || "Buscando..."}
          />
        ) : (
          <div className="">

          <COLUMS_SHOW
            filtersColumsView={filtersColumsView}
            datacolums={datacolumns}
         />
          
          <div className="containerCutBox">
                <div className="expen-8 color-1">
                  <FormControlLabel
                    control={
                      <MaterialUISwitch
                        sx={{ m: 1 }}
                        onChange={handlechecked}
                        checked={filtersColumsView}
                      />
                    }
                    label={
                      filtersColumsView === true
                        ? "Activar Filtros"
                        : "Desactivar Filtros"
                    }
                  />
                </div>
            </div>
            <br></br><br></br>
            <div className="contenerControllerCFDI">
              <div className="bp-1f">
                <input
                  type="text"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha inicial"
                  onChange={(event) => setfi(event.target.value)}
                ></input>
              </div>
              <div className="bp-2f">
                <input
                  type="text"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
                ></input>
              </div>
              <div className="bp-3f">
                <select
                  id="canceladas-si-no"
                  onChange={(event) => setCancel(event.target.value)}
                >
                  <option>Estado de la factura</option>
                  <option value={true}>CANCELADO</option>
                  <option value={false}>VIGENTE</option>
                  <option value={""}>TODOS</option>
                </select>
              </div>
              <div className="bp-4f">
                <input
                  type="text"
                  placeholder="Busqueda por RFC"
                  className="filtroRFC"
                  onChange={(event) => setRFC(event.target.value)}
                ></input>
              </div>
              <div className="bp-5f">
                <input
                  type="text"
                  placeholder="Busqueda por socio de negocios"
                  onChange={(event) => setSDN(event.target.value)}
                ></input>
              </div>
              <div className="bp-6f">
                <select onChange={(event) => setTipoDoc(event.target.value)}>
                  <option>Tipo de documento</option>
                  <option value={"FACTURA"}>FACTURA</option>
                  <option value={"NOTA DE CREDITO"}>NOTA DE CRÉDITO</option>
                  <option value={"TRASLADO"}>CARTA PORTE</option>
                  <option value={"PAGO"}>PAGO</option>
                  <option value={"null"}>TODOS</option>
                </select>
              </div>

              <div className="bp-7f">
                <button className="button" onClick={handleSubmit} type="button">
                  Buscar
                </button>
              </div>
              <div className="bp-8f">
              {/* <img
                    alt=""
                    src={REPORT}
                    className="reportInvoice"
                    title="GENERAR REPORTE OPORTUNO"
                    onClick={async () => {
                      await CustomDialog(
                        <ReenvioCorreo report={true}
                        ></ReenvioCorreo>,
                        {
                          className: "modalTItle",
                          title:
                            'Nota: SEPARA LOS CORREOS ELECTRÓNICOS A TRAVÉS DE UNA "," SI DESEAS ENVIAR MÁS DE UN CORREO ELECTRÓNICO',
                          showCloseIcon: true,
                        }
                      );
                    }}
                  ></img> */}
              </div>
            </div>
            <StickyTable>
              <TableContainer
                nametable={`Facturas del ${fi} al ${ff}`}
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                consultas={"Facturas"}
                exportar={true} 
                Grantotal={true}
                GrantotalCabeceras={
                  ["Fecha", 
                  "Ticket",
                  "Punto de Venta",
                  "Serie",
                  "Folio",
                  "Cliente",
                  "Serie/Folio",
                  "RFC",
                  "Metodo de pago",
                  "Tipo de comprobante",
                  "Estado",
                  "Usuario"]
                }
                renderRowSubComponent={(row) => {
                  return (
                    <div style={{ padding: "20px" }}>
                      <SubTable
                        reconsult={reconsult}
                        data={data}
                        row={row}
                        columns={columns}
                        defaultPageSize={3}
                        showPagination={false}
                      />
                    </div>
                  );
                }}
              />
            </StickyTable>
          </div>
        )}
      </div>
    </div>
    
    </>
    
  );
};

export default Consult_invoice;
