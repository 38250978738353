//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { StickyTable} from "react-sticky-table";

//COMPONENTES
import SubTableContainer from "../../../../main_components/Table/SubTableContainer";
import { get_invoices_detail } from "../../../../services/invoices/invoice";
import { get_invoices } from "../../../../services/invoices/invoice";
//ESTILOS
import "../../../estilo.css";
import "../../../modal.css";
import "../../../table.css";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";

function SubTable(props) {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);


  const handle_get_invoice = async (event) => {
    event?.preventDefault();
    setData([]);
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            var dt = await get_invoices("","","","","", "",sessionStorage.getItem("dataSuntablefiltro"));
            setData2(dt.data);
          } catch (err) {
            setData([]);
          }  
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  const handle_get_invoice_detail = async (event) => {
    event?.preventDefault();
    setData([]);
    try{
      var rf_token=await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            const dt= await get_invoices_detail(sessionStorage.getItem("dataSuntablefiltroID"),props.row.original.uuid_sat);
            setData(dt.data);
          } catch (err) {
            setData([]);
          }   
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };
  useEffect(() => { 
    handle_get_invoice(); 
    handle_get_invoice_detail(); 
  }, [props.reconsult]);
  
  console.log(data2[0]?.cancel);

  console.log(data2);

  const columns = useMemo(
    () => [
      {
        Header: "Cantidad",
        accessor: "quantity",
      },
      {
        Header: "Unidad",
        accessor: "unity",
      },
      {
        Header: "Descripción",
        accessor: "description",
      },
      {
        Header: "Descuento",
        accessor: (d) => {
          return d.discount
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.discount)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.discount);
        },
      },

      {
        Header: "Precio Unitario",
        accessor: (d) => {
          return d.unitary_price
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.unitary_price)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.unitary_price);
        },
      },

      {
        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
      },
    ],
    []
  );

  const columnsCancel = useMemo(
    () => [
      {
        Header: "Estado",
        accessor: "status",
      },
      {
        Header: "¿Es cancelable?",
        accessor: "its_cancelable",
      },
      {
        Header: "Estado de cancelación",
        accessor: "cancelation_status",
      },
    ],
    []
  );

  return (
    <div className="TableConsultarTodo2">
      <StickyTable>
        <SubTableContainer
          columns={data2[0]?.cancel === true ? columnsCancel : columns}
          data={data2[0]?.cancel === true ? data2 : data}
          style={{ overflowx: 10 }}
        />
      </StickyTable>
    </div>
  );
}

export default SubTable;
