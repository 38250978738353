import React, { Fragment, useEffect, useState } from "react";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col, Button, CustomInput } from "reactstrap";
import { Filter, DefaultColumnFilter } from "../../components_cfdi/filters";
import exportE from "../../imgComponents/export.png";
import { Exportgs } from "./Exportgs";
import exportPDF from "../../imgComponents/pdf dowload.png";
import "./tablecontainer.css";
import "jspdf-autotable";
import { useResizeColumns } from 'react-table';
import "../../componentsNewDashboard/dashboar.css";

const multiSelectFilterFn=(rows, index, filterValue)=> {
  if (filterValue && Array.isArray(filterValue) && filterValue.length > 0) {
    console.log(rows)
    return rows.filter(row => {
      const rowValue = row.values[index];
      return filterValue.some(filter => filter.value === rowValue);
    });
  } else {
    return rows;
  }
}

const TableContainer = ({
  paginado,
  consultas,
  exportar,
  columns,
  data,
  renderRowSubComponent,
  nametable,
  Gmodal,
  minfilas,
  Grantotal,
  GrantotalCabeceras
  
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    footerGroups,
    canNextPage,
    rows,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    exportData,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        includes: multiSelectFilterFn,
      },
      initialState: {
        hiddenColumns: columns
          .filter((col) => col.show === false)
          .map((col) => col.id),
        pageIndex: 0,
        pageSize: minfilas===true?6:10,
      },
      getExportFileBlob,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useExportData,
    useRowSelect,
  );

  //gran total para table container se indica en true si se requiere 

  

  const [counter, setcounter]=useState(0);
  
  const [datagg, setdatagg]=useState([]);
  const [dataggf, setdataggf]=useState([]);

  useEffect(() => {
    setTimeout(() => {
      handlegrantotal();
    }, 2000);
  }, [page.length, columns, counter, rows.length]);
    
  const handlegrantotal = ()=>{

    var cant = columns.filter(item=>item.show!==false);
    var gtt = new Array(cant.length).fill(0);
    var gttf = new Array(cant.length).fill("");
      for(var i=0; i<page.length; i++){
        for(var j = 0; j<page[i].cells.length;j++){
          
          if (GrantotalCabeceras?.includes(cant[j]?.Header)) {
            // El valor está presente en el arreglo 
             gtt[j] = "";
             gttf[j] = "no incluido"; 
          } else {
          
            if (typeof page[i].cells[j].value === "string" && page[i].cells[j].value.startsWith("$")) {
              const newValue = parseFloat(page[i].cells[j].value.replace(/[$,]/g, ""));
              gtt[j] += newValue;
              gttf[j] = "moneda";
            }
            else if(typeof page[i].cells[j].value === "string" && page[i].cells[j].value.endsWith("%")){
              const newValue = parseFloat(page[i].cells[j].value.replace(/[%,]/g, ""));
              gtt[j] += newValue;
              gttf[j] = "porcentaje"
            }else if(!isNaN(page[i].cells[j].value) && typeof parseFloat(page[i].cells[j].value) === "number"){
              const newValue = parseFloat(page[i].cells[j].value);
              gtt[j] += newValue;
              gttf[j] = "numerico";
            }else{
              gtt[j] = "";
              gttf[j] = "text";
            }
          }
        }
      }
      setdatagg(gtt);
      setdataggf(gttf);
  }

    

  const [gs, setgs] = useState([]);
  const handleshet = () => {
    exportData("gs", false);
  };

  function getExportFileBlob({ columns, data, fileType, fileName }) {
    console.log(fileType);
    var totals2 = new Array(page[0]?.length).fill(0);
    console.log(totals2)
    if (fileType === "gs") {
      console.log("entra gs");

      var data2 = data.slice(0, page.length)
      console.log(data2.length);

      var totals = new Array(data2[0].length).fill(0);

      for (let i = 0; i < data2.length; i++) {
        for (let j = 0; j < data2[i].length; j++) {
          if (typeof data2[i][j] === "string" && data2[i][j].startsWith("$")) {
            const newValue = parseFloat(data2[i][j].replace(/[$,]/g, ""));
            data2[i][j] = isNaN(newValue)?0:newValue;
            totals[j]+= isNaN(newValue)?0:newValue;
          }else{
            totals[j] = "vacio";
          }
        }
      }
      const headerNames = columns.map((col) => col.exportValue);
      //const csvString = Papa.unparse({ fields: headerNames, data });
      // Agregar los totales al final del arreglo
      const totalsRow = totals.map(total => total==="vacio"?" ":total?.toFixed(2));
      data2.push(totalsRow);
      // Actualizar el CSV con los totales
      const csvStringWithTotals = Papa.unparse({ fields: headerNames, data: data2 });
      setgs(csvStringWithTotals);
    }else 
    if (fileType === "csv") {



      var totalscsv = new Array(columns.length).fill(0);
      // CSV example      
      var longitud = data.slice(0,page.length);



        for (let i = 0; i < longitud.length; i++) {
          for (let j = 0; j < longitud[i].length; j++) {
            if (typeof longitud[i][j] === "string" && longitud[i][j].startsWith("$")) {
              const newValue = Number(data[i][j].replace(/[$,]/g, ""));
              longitud[i][j] = newValue===undefined?0:newValue;
              console.log(newValue===undefined?0:newValue);
              totalscsv[j] += newValue;
            }else{
              totalscsv[j] = "vacio";
            }
          }
        }

      console.log(totalscsv);

      const headerNames = columns.map((col) => col.exportValue);
      const totalsRow = totalscsv.map(total=>total==="vacio"?"":total);
      longitud.push(totalsRow);
      const csvString = Papa.unparse({ fields: headerNames, data: longitud});
      const link = document.createElement("a");
      link.href = URL.createObjectURL(new Blob([csvString], { type: "text/csv" }));
      const tiempoTranscurrido = Date.now();
      const hoy = new Date(tiempoTranscurrido);
      hoy.toUTCString();
      link.download = "data.csv";
      link.click();

      return URL.revokeObjectURL(link.href);

      
    } else if (fileType === "xlsx") {
      // XLSX example

      const header = columns.map((c) => c.exportValue);
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[index];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);
       return false;
    }
    if (fileType === "pdf") { 
      const headerNames = columns.map((column) => column.exportValue);
      var pdfexport = [...data, ...[datagg.slice(4)]];   
      const doc = new JsPDF("ledger");
      doc.autoTable({
        head: [headerNames],
        body: pdfexport,
        margin: { top: 10, left: 10 },
        styles: {
          minCellHeight: 5,
          halign: "left",
          valign: "top",
          fontSize: headerNames.length>10?7:13,
          cellWidth: headerNames.length>=10?20:null,
        },
      });
     // doc.save(`${fileName}.pdf`);
    var pdfBlob = doc.output("blob");
    window.open(URL.createObjectURL(pdfBlob));
     
    return false;
    }

    // Other formats goes here
    return false;
  }
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };
  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div className="containerExportC">
        <div className="expen-6">
          {exportar === true ? (
            <>
              <img
                src={exportPDF}
                className="exportartablecontainerpdf"
                alt=""
                id=""
                title="Exportar"
                onClick={() => {
                  exportData("pdf", false);
                  setOpen(false);
                }}
              ></img>
              <img
                src={exportE}
                className="exportartablecontainer"
                alt=""
                id=""
                title="Exportar"
                onClick={() => {
                  exportData("csv", false);
                  setOpen(false);
                }}
              ></img>
              <Exportgs
                data={data}
                page={page}
                handleshet={handleshet}
                gs={gs}
                nametable={nametable}
              ></Exportgs>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Fragment>
        <div className={page?.length <= 10 ?"":"tableFixHead"}>
          <Table bordered hover {...getTableProps()}>
            <thead className="fijoHeader">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}
                      // style={{ width: column.width }}
                      // {...column.getResizerProps()}
                    >
                      <div {...column.getSortByToggleProps()} title={"Ordenar"}>
                        {column.render("Header")}
                        {generateSortingIndicator(column)}
                      </div>
                      <Filter column={column} />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded && (
                      <tr>
                        <td colSpan={visibleColumns.length}>
                          {renderRowSubComponent(row)}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
            {
              Grantotal ===true?
            
            <tbody className="totalesfijo">
              <tr>
                  {
                  datagg.map((item, index)=>{
                    console.log(item);
                  })  
                  }
                 {
                  datagg.map((item, index)=>(
                    <td>
                      {
                        index===0?"Gran Total":
                        item==="NaNNaN"?null:
                        item===""?"":
                          dataggf[index]==="moneda"?
                            Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                            })?.format(item):
                          dataggf[index]==="porcentaje"?
                           item>100? 100+"%":item?.toFixed(2)+"%":
                          dataggf[index]==="numerico"?
                            item?.toFixed(2)
                          :""
                      }
                    </td>
                  ))
                }
              </tr>
            </tbody>:<></>
              
              
             
            }
           
          </Table>

          <div
            className="sk-fading-circle"
            style={{ display: Gmodal === true ? "" : "none" }}
          >
            <div className="sk-circle1 sk-circle"></div>
            <div className="sk-circle2 sk-circle"></div>
            <div className="sk-circle3 sk-circle"></div>
            <div className="sk-circle4 sk-circle"></div>
            <div className="sk-circle5 sk-circle"></div>
            <div className="sk-circle6 sk-circle"></div>
            <div className="sk-circle7 sk-circle"></div>
            <div className="sk-circle8 sk-circle"></div>
            <div className="sk-circle9 sk-circle"></div>
            <div className="sk-circle10 sk-circle"></div>
            <div className="sk-circle11 sk-circle"></div>
            <div className="sk-circle12 sk-circle"></div>
          </div>
        </div>

        <Row style={{ maxWidth: 1300, margin: "0 auto", textAlign: "center" }}>
          <Col md={3}>
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={()=>{
                previousPage();
                setcounter(counter+1);
              }}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </Col>
          <Col md={2} style={{ marginTop: 7 }}>
            Pagina{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>
          </Col>

          <Col md={4}>
            <CustomInput
              id="select"
              type="select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {

              minfilas===true && data.length<=10?
                [
                  6,
                  data.length,
                ].map((pageSize, index) => (
                  <option key={index} value={pageSize}>
                    {paginado} {pageSize}
                  </option>
                ))
              :
              minfilas===true && data.length>10?
              [
                6,
                Math.round(data.length / 4),
                Math.round(data.length / 2),
                data.length,
              ].map((pageSize, index) => (
                <option key={index} value={pageSize}>
                  {paginado} {pageSize}
                </option>
              ))
              :
              data.length < 10
                ? [
                    data.length,
                    Math.round(data.length / 4),
                    Math.round(data.length / 2),
                  ].map((pageSize, index) => (
                    <option key={index} value={pageSize}>
                      {paginado} {pageSize}
                    </option>
                  ))
                :
                [
                    10,
                    Math.round(data.length / 4),
                    Math.round(data.length / 2),
                    data.length,
                  ].map((pageSize, index) => (
                    <option key={index} value={pageSize}>
                      {paginado} {pageSize}
                    </option>
                  ))
                }
            </CustomInput>
          </Col>
          <Col md={3}>
            <Button color="primary" 
              onClick={()=>{
                nextPage();
                setcounter(counter+1);
              }} 
              disabled={!canNextPage}
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </Col>
        </Row>
        <h3 className="footer-table-container">
          {consultas} {page.length} de {data.length}
        </h3>
      </Fragment>
    </>
  );
};

export default TableContainer;
