//PAQUETERIAS
import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
//COMPONENTES
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
//IMAGENES/ICONOS
import Spinner from "../../../imgComponents/S.gif";

import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { post_taxes_config } from "../../../services/invoices/invoice";


const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const typeTR = [
  { value: "Traslado", label: "Traslado" },
  { value: "Retencion", label: "Retencion" },
];

const typeFactorTC = [
  { value: "Tasa", label: "Tasa" },
  { value: "Cuota", label: "Cuota" },
];

const Set_configure = ({id_tax}) => {
  const [Rate, setRate] = React.useState("");
  const [Type, setType] = React.useState("Traslado");
  const [TypeFactor, setTypefactor] = React.useState("Tasa");
  const [open, setOpen] = useState(false);
  const [id_taxes, setId_taxes] = useState(id_tax);
  const dialog = useDialog();

  const handelRate = (event) => { setRate(event?.target?.value);};

  const handelType = (event) => {setType(event?.target?.value);};

  const handelTypeFactor = (event) => {setTypefactor(event?.target?.value);};

  useEffect(() => {
    handelRate();
  }, []);
  useEffect(() => {
    handelType();
  }, []);
  useEffect(() => {
    handelTypeFactor();
  }, []);

  const handleSubmit = async(event) => {
    event?.preventDefault();
    setOpen(true);
    refresh_token ();
    var data={ 
      rate: Number(Rate) , 
      type: (Type===undefined)?"Traslado":Type, 
      c_tipofactor: (TypeFactor===undefined)?"Tasa":TypeFactor
    };   
    try{
      var rf_token = await refresh_token(id_taxes, data);
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await post_taxes_config()
            setOpen(false); 
            alert('Éxito, configuración asignada correctamente'); 
            dialog.close();
          }catch(err){
            catchErrorModul("Impuestos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
            setOpen(false); 
            alert("Error.,"+ err.response.data.errorMessage); 
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }

    /*
      let axiosConfig = {headers: { Authorization: sessionStorage.getItem("getIdTokenRefresh"), "access-token": sessionStorage.getItem("getAccesTokenRefresh"),"Content-Type": "application/json",}};
      axios.post(`https://inxmul26u8.execute-api.us-east-1.amazonaws.com/od/taxes/${id_taxes}/config`, data, axiosConfig)
      .then((res) => { 
        setOpen(false); 
        alert('Éxito, configuración asignada correctamente'); dialog.close();
      })
      .catch((err) => { 
        catchErrorModul("Impuestos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
        setOpen(false); 
        alert("Error.,"+ err.response.data.errorMessage); 
      })
    */
  };

  return (
    <div>
        <div>
            <Modal
              open={open}
              onClose={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
                <Box sx={style2} >
                  <div className="centermodaldescarga">
                    <Typography id="modal-modal-title"  className=""  variant="h6" component="h2">
                      <img src={Spinner}></img>
                    </Typography>
                    <Typography id="modal-modal-description" className="" sx={{ mt: 2 }}>  
                      {"Creando configuración..."}
                    </Typography>
                    </div>
                  </Box>
             </Modal>
        </div>
      <div>
        <form>
          <Box>
          <ValidationTextField
              label="Tarifa"
              variant="outlined"
              placeholder="Tarifa"
              sx={{ m: 2, width: "20ch" }}
              onChange={handelRate}
              value={Rate}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              select
              label="Tipo"
              value={Type}
              sx={{ m: 1.5, width: "20ch" }}
              onClick={handelType}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {typeTR.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.label}
                >
                  {option.label}
                </option>
              ))}
            </TextField>

            <TextField
              select
              label="Tipo factor"
              value={TypeFactor}
              sx={{ m: 1.5, width: "20ch" }}
              onClick={handelTypeFactor}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {typeFactorTC.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.label}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>

          <br></br>
          <br></br>

          <Box>
            <Button
              variant="contained"
              type="submit"
              className="ButtonModal"
              sx={{ m: 1.5, width: "30%" }}
              onClick={handleSubmit}
            >
              Agregar
            </Button>
            <Button
              variant="contained"
              className="ButtonModal2"
              sx={{ m: 1.5, width: "30%" }}
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default Set_configure;
