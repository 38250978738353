//PAQUETERIAS
import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
//COMPONENTES
import SubTable from "./Get_Tax_Concept";
//IMAGENES
import Spinner from "../../../imgComponents/S.gif";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_taxes_configs, post_concepts_taxes } from "../../../services/invoices/invoice";
//ESTILOS
const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const TypePerson = [
  { value: "TODOS", label: "TODOS" },
  { value: "MORAL", label: "MORAL" },
  { value: "FISICA", label: "FISICA" },
];


const Set_Tax_Concept = ({base,id_conceptADD}) => {
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [Base, setBase] = React.useState(base);
  const [tax, setTax] = React.useState("");
  const [TipoPersona, setTipoPersona] = React.useState("TODOS");
  const [result, setResult] = React.useState("");

  const dialog = useDialog(); 

  const handelBase = (event) => { setBase(event.target.value);};
  const handelTax = (event) => { 
    const id_filter=(event?.target?.value === undefined?1:event?.target?.value);
    setTax(id_filter);
    console.log(id_filter);
    const result = data.filter(item => item.id === Number(id_filter));    
    setResult(result);
  console.log(result);
  };

  const handelTypePerson = (event) => { setTipoPersona(event.target.value); };
  


  const handleSubmit = async(event) => {
    event?.preventDefault();
    var data={
      base: Base,
      tasa_o_cuota: result[0].rate,
      tipo_factor: result[0].c_tipo_factor, 
      impuesto: result[0].tax_name , 
      person_type:TipoPersona,	
      tipo: result[0].type ,
      id_tax_config_fk:tax
    };  
    
    setOpen(true);

    try{
      var rf_token=await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await post_concepts_taxes(id_conceptADD, data)
            setOpen(false);
            alert('Se agrego correctamente el impuesto al concepto'); 
            dialog.close();
          }catch(err){
            catchErrorModul("Conceptos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
            setOpen(false); 
            alert("Error.,"+ err.response.data.errorMessage); 
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
    /*
    let axiosConfig = {headers: { Authorization: sessionStorage.getItem("getIdTokenRefresh"), "access-token": sessionStorage.getItem("getAccesTokenRefresh"),"Content-Type": "application/json",}};
    axios
    .post(`https://inxmul26u8.execute-api.us-east-1.amazonaws.com/od/concepts/${id_conceptADD}/taxes`, data, axiosConfig)
    .then((res) => { 
      setOpen(false); alert('Se agrego correctamente el impuesto al concepto'); 
      dialog.close();  })
    .catch((err) => { 
      catchErrorModul("Conceptos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
      setOpen(false); 
      alert("Error.,"+ err.response.data.errorMessage); 
    })
    */
  
  };
  const handleUnidades = async (event) => {
    event?.preventDefault();
    try{
      var rf_token=await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_taxes_configs();
            const data = d === null ?[]: d.data;
            setData(data);
          }catch(err){
            setData([]);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
    /*
    axios
      .get(`https://inxmul26u8.execute-api.us-east-1.amazonaws.com/od/taxes/configs?`,{headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        setData(data);
      })
      .catch((err) => {
        setData([]);
      });
      */
  };

  
 
  

  useEffect(() => {
    handleUnidades();
  }, []);

  return (
    <div>
        <div>
            <Modal
              open={open}
              onClose={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
                <Box sx={style2} >
                  <div className="centermodaldescarga">
                    <Typography id="modal-modal-title"  className=""  variant="h6" component="h2">
                      <img src={Spinner}></img>
                    </Typography>
                    <Typography id="modal-modal-description" className="" sx={{ mt: 2 }}>  
                      {"Agregando impuesto..."}
                    </Typography>
                    </div>
                  </Box>
             </Modal>
        </div>
      <div>
        <form>
          <Box>
            <ValidationTextField
                label="Base"
                variant="outlined"
                placeholder="Base"
                sx={{ m: 2, width: "28%" }}
                value={Base}
                InputLabelProps={{ shrink: true }}
             />

            <TextField
              select
              label="Tipo de persona"
              value={TipoPersona}
              sx={{ m: 1.5, width: "28%" }}
              onChange={handelTypePerson}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {TypePerson.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.value}
                >
                  {option.value}
                </option>
              ))}
            </TextField>

                
            <TextField
              select
              label="Impuesto"
              value={tax}
              sx={{ m: 1.5, width: "28%" }}
              onClick={handelTax}
              onChange={handelTax}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {data.map((option) => (
                <option
                  value={option.id}
                  selected={option}
                >
                  {option.tax_name +"_"+option.rate }
                </option>
              ))}
            </TextField>


          </Box>
          <Box>

          <ValidationTextField
                label="Tasa/Cuota"
                variant="outlined"
                placeholder="Tasa/Cuota"
                sx={{ m: 2, width: "25.5%" }}
                value={result[0]?.rate}
                InputLabelProps={{ shrink: true }}
              />
                        
            <ValidationTextField
                label="Tipo Factor"
                variant="outlined"
                placeholder="Tipo Factor"
                sx={{ m: 2, width: "28%" }}
                value={result[0]?.c_tipo_factor}
                InputLabelProps={{ shrink: true }}
              />
       
            <ValidationTextField
                label="Tipo"
                variant="outlined"
                placeholder="Tipo"
                sx={{ m: 2, width: "28%" }}
                value={result[0]?.type}
                InputLabelProps={{ shrink: true }}
              />
            
          </Box>

          <br></br>
          <br></br>

          <Box>
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              onClick={handleSubmit}
              className="ButtonModal"
            >
              Agregar
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal2"

              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
            
          </Box>
        </form>
      </div>
    </div>
  );
}
export default Set_Tax_Concept;
