//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import TableContainer from "../../main_components/Table/TableContainer";
//ESTILOS
import "./rulesSE.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { delete_expense_income_rules, get_expense_income_rules } from "../../services/expenses/expenses";
import OPERATIONS_RULES_SE from "./tools/op_rules_se";

const RULES_SE= () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;

  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

useEffect(()=>{
  handleSubmit();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);


function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n===true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}

const handleSubmit =async () => {
  
    if(entrada===0){
      entrada=entrada+1;
      setmenssage("Consultando Reglas para envío de gastos...");
      setmodalGeneral(true);
      setmodalT(true);  
    }else{
      setmodalT(true);
    }

    setopen(true);
    setData([]);
    
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_expense_income_rules();
            const data = d === null ? [] : d.data.exp_income_rules
            setData(data);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }catch(err){
            console.log("error", err);
            setData([]);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
};

const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            id="crearRol"
            src={CREATE}
            onClick={() => {
              CustomDialog(               
             <div>
              <OPERATIONS_RULES_SE operation={"CREATE"} handleSubmit={handleSubmit} reconsultar={reconsultar} alertas={alertas}/>
             </div>,
           {
             className: "modalpuntosventa",
             title:"Crear nueva regla para envío de gastos",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Crear Regla de Envío de Gastos"
            className="cancel"
            alt=""
          ></img>
        </div>
      ),
      
      id: "Acciones",
      Cell: ({ row }) => {
      const DeleteSector = async()=>{  
      
      setmodalT(true);
      refresh_token ();
      setData([]);
      setopen(true);

      try{
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try{
              const d = await delete_expense_income_rules(row.original.id);
              console.log(d);
              alertas("Regla de Gasto Eliminada", true);
              handleSubmit();
            }catch(err){
              console.log("error", err);
              setmodalT(false);
              alertas("Error, no se pudo eliminar la Regla de Gasto", false);
            }
          }, 1000);
        }else{}
      }catch(err){
        console.log(err);
      }
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de Eliminar la Regla de Gastos','Eliminar Regla de Gastos','Si','No');
                  if(result){
                    DeleteSector()
                    }
                  }            
                }
              className="centerText, cancel"
              title="Eliminar Regla de Gastos"
              alt=""
            ></img>
              &nbsp;
            <img
              src={Edit}
              onClick={() => {
                   CustomDialog(               
                 <div>
                    <OPERATIONS_RULES_SE operation={"EDIT"} row={row} handleSubmit={handleSubmit} reconsultar={reconsultar} alertas={alertas}></OPERATIONS_RULES_SE>
                 </div>,
               {
                 className: "modalpuntosventa",
                 title:"Editar Regla de Gasto",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              className="centerText, cancel"
              title="Editar Regla de Gasto"
              alt=""
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Usuario",
      accessor:"user_name"
    },
    {
      Header:"Habilitado",
      accessor: (d)=>{
        return d.enable===true?"Habilitado":"Deshabilitado"
      }      
    },
    {
      Header: "Días Antes",
      accessor: "lower_limit_days"
    },
    {
      Header: "Semanas Antes",
      accessor:"lower_limit_week"
    },
    {
      Header:"Meses Antes",
      accessor:"lower_limit_month"
    },
    {
      Header:"Bloqueado", 
      accessor: (d)=>{
        return d.blocked===true?"SI":"NO"
      }      
    },
    {
      Header:"Monto Inferior",
      accessor: (d)=>
      {
        return d.lower_limit_ammount===null?
        Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(0.0)
        :Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(d.lower_limit_ammount);
      }
    },
    {
      Header:"Monto Superior",
      accessor: (d) =>{
        return d.upper_limit_ammount===null?
        Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(0.0)
        :Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(d.upper_limit_ammount);
      }
    }
    
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
);
  return (
    <>
      <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR REGLAS DE GASTOS", path: null},
                ]
              }
      ></BREADCRUMBS>

<div className="TableRules">
  <div className="">         
          <>
          
          <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
          <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
          ></MODAL_TABLE>
          <StickyTable>
            <TableContainer
              paginado={"Reglas de Gastos"}
              consultas={"Reglas de Gastos Consultadas"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
          </>   
          </div>
        </div>
    </>
  );
}

export default RULES_SE;
