/* eslint-disable jsx-a11y/alt-text */
//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable} from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import TableContainer from "../../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
//ESTILOS
import "../../../components_cfdi/estilo.css";
import '../../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../../components_cfdi/modal.css";
import "./commissions.css";
//IMAGENES/ICONOS
import Delete from "../../../imgComponents/borrar.png";
import CREATE from "../../../imgComponents/create.png";
import Edit from "../../../imgComponents/save.png";
import { delete_commissions, get_commissions } from "../../../services/reports/commissions";
import { OPERATIONS_COMMISSIONS } from "./tools/op_commss";


const COMMISSIONS= () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

useEffect(()=>{
  handleSubmit();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n===true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}
const handleSubmit = async () => {
    console.log("entra a consultar todas las comisiones");      
    if(entrada===0){
      entrada=entrada+1;
      setmenssage("Consultando Comisiones...");
      setmodalGeneral(true);
      setmodalT(true);
    }else{
      setmodalT(true);
    }
    setopen(true);
    setData([]);

    try {
      var rf_token = await refresh_token();
      if(rf_token ===true){
        setTimeout(async() => {
          try{
            var d = await get_commissions();
            console.log(d);
            setData(d === null ? [] : d.data);  
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);  
          }catch(err){
            setData([]);
            setopen(false)
            setmodalGeneral(false);
            setmodalT(false);
          }     
        }, 1000);
      }else{}
    } catch (err) {
     console.log(err);
    }
};
const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            id="crearRol"
            src={CREATE}
            onClick={async () => {
              CustomDialog(               
             <div>
                <OPERATIONS_COMMISSIONS operation="CREATE" handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></OPERATIONS_COMMISSIONS>
             </div>,
           {
             className: "modalTItle",
             title:"Crear Nueva Comisión",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Crear Comisión"
            className="cancel"
          ></img>
        </div>
      ),
      
      id: "Acciones",
      Cell: ({ row }) => {
      const DeleteSector = async () => {  
      
      setmodalT(true);
      setData([]);
      setopen(true);
        try {
          var rf_token = await refresh_token();
          if(rf_token ===true){
            setTimeout(async() => {
              try{
                const dt = await delete_commissions(row.original.id);
                console.log(dt);
                alertas("Comisión Eliminada", true);
                handleSubmit( );
              }catch(err){
                console.log("error", err);
                setopen(false);
                alertas("Error, no se pudo eliminar la comisión", false);
                setmodalT(false);
                handleSubmit();
              }
            }, 1000);
          }else{}
        } catch (err) {
          console.log(err);
        }
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar la comisión?','Eliminar Comisión','Si','No');
                  if(result){
                    DeleteSector()
                    }
                  }            
                }
              className="centerText, cancel"
              title="Eliminar Comisión"
            ></img>
              &nbsp;
            <img
              src={Edit}
              onClick={
                async () => {
                  CustomDialog(               
                 <div>
                    <OPERATIONS_COMMISSIONS operation="EDIT" row={row} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></OPERATIONS_COMMISSIONS>
                  </div>,
               {
                 className: "modalTItle",
                 title:"Editar Comisión",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              className="centerText, cancel"
              title="Editar Comisión"
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Fecha de Inicio",
      accessor:"date_start"
    },
    {
      Header:"Fecha Final",
      accessor:"date_end"
    },
    {
      Header: "Tipo de Venta",
      accessor:"sale_type"
    },
    {
      Header: "Comisión Preventa Ventas Propias",
      accessor: (d) => {
        return d.seller_own_sales_com+"%"
      },    
    },
    {
      Header: "Comisión Reparto Ventas Propias",
      accessor: (d) => {
        return d.dealer_own_sales_com+"%"
      },    
    },
    {
      Header: "Comisión Ayudante Ventas Propias",
      accessor: (d) => {
        return d.assistant_own_sales_com+"%"
      },   
    },
    {
      Header: "Comisión Preventa Ventas Secundarias",
      accessor: (d) => {
        return d.seller_sec_sales_com+"%"
      }
    },
    {
      Header:"Comisión Reparto Ventas Secundarias",
      accessor: (d) => {
        return d.dealer_sec_sales_com+"%"
      },   
    },
    {
      Header:"Comisión Ayudante Ventas Secundarias",
      accessor: (d) => {
        return d.assistant_sec_sales_com+"%"
      },   
    }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
);
  return (
    <>
    <BREADCRUMBS niveles={
                [
                  {label: "ASIGNACIÓN DE PERSONAL", path: "/daily_staff"},
                  {label: "REGISTRO DE COMISIONES", path: null},
                ]
              }
      ></BREADCRUMBS>

<div className="TableCommss">
  <div className="">         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
          <>
         
          <StickyTable>
            <TableContainer
              paginado={"Comisiones"}
              consultas={"Comisiones Consultadas"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
          </>   
          </div>
        </div>
    </>
  );
}

export default COMMISSIONS;
